import Amplify from "@aws-amplify/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { createBrowserHistory } from "history";
import { useReducer } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiConfig, authConfig } from "./aws-exports";
import Billing from "./screens/authorized/Billing";
import Home from "./screens/authorized/Home";
import SelectAccount from "./screens/authorized/SelectAccount";
import SignIn from "./screens/unauthorized/SignIn";
import Verify from "./screens/unauthorized/Verify";
import { reducer, initialState } from "./State"
import { Context } from "./Context";
import theme from "./theme";

const history = createBrowserHistory();

Amplify.configure({
  ...authConfig,
  ...apiConfig,
  Analytics: {
    disabled: false,
  },
});

function App() {
  // Define global state.
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Context.Provider value={[state, dispatch]}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router history={history}>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/home" exact component={Home} />
            <Route path="/selectAccount" exact component={SelectAccount} />
            <Route path="/billing" exact component={Billing} />
            <Route path="/signin" exact component={SignIn} />
            <Route path="/verify" exact component={Verify} />
          </Switch>
        </Router>
      </ThemeProvider>
    </Context.Provider>
  );
}

export default App;

const styles = {
  logoContainer: {
    marginTop: 25,
    marginBottom: 15,
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    marginTop: 15,
    width: "400px",
    height: "auto",
  },
};