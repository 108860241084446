import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import ScreenSection from "../../../../components/ScreenSection";
import { COLORS, FONT_SIZES, PADDING } from "../../../../constants";
import { Context } from "../../../../Context";
import { useAccountOrSelectAccount } from "../../../../auth";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: FONT_SIZES.large,
    color: COLORS.darkBlue,
  },
}))(Tooltip);

const EVENT_TYPE_TO_DISPLAY_TEXT = {
  alert: "Alert",
};

const bytesToGigabytes = (bytes) => {
  let gigabytes = (bytes / 1024 / 1024 / 1024).toString();

  if (gigabytes.indexOf(".") !== -1) {
    return gigabytes.slice(0, gigabytes.indexOf(".") + 3);
  } else {
    return gigabytes;
  }
};

const EventMark = (props) => {
  const classes = useStyles();
  return (
    <LightTooltip
      title={
        <div style={{ textAlign: "center" }}>
          <span className={classes.eventMarkTextType}>
            {EVENT_TYPE_TO_DISPLAY_TEXT[props.type]}
          </span>
          <br />
          <span className={classes.eventMarkText}>{props.text}</span>
        </div>
      }
    >
      <IconButton
        size="small"
        style={{
          position: "absolute",
          marginLeft: `calc(${props.percent}% - 11px)`,
          marginTop: "-6px",
          zIndex: 100,
          color: COLORS.amber,
        }}
      >
        <FiberManualRecordIcon
          style={{
            fontSize: 16,
          }}
        />
      </IconButton>
      {/* <div
        className={classes.eventMark}
        style={{ marginLeft: `${props.percent}%` }}
      >
        <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className={classes.eventMarkTextContainer}>
          <span className={classes.eventMarkTextType}>
            {EVENT_TYPE_TO_DISPLAY_TEXT[props.type]}
          </span>
          <br />
          <span className={classes.eventMarkText}>{props.text}</span>
        </div>
          </div>
      </div> */}
    </LightTooltip>
  );
};

const CurrentMark = (props) => {
  const classes = useStyles();
  return (
    <>
      <div
        className={classes.currentMark}
        style={{ width: `${props.percent}%` }}
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        ></div>
      </div>
      <div style={{ position: "absolute", marginLeft: `${props.percent}%` }}>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className={classes.currentMarkTextContainer}>
            <span className={classes.currentMarkTextNumber}>
              {props.currentLevelText}
            </span>
            <span className={classes.currentMarkTextUnit}>GB</span>
            <br />
            <span className={classes.currentMarkText}>Used</span>
          </div>
          <div className={classes.currentMarkLine}></div>
        </div>
      </div>
    </>
  );
};

const WindowMark = (props) => {
  const classes = useStyles();
  return (
    <div
      className={classes.windowMark}
      style={{ marginLeft: `${props.percent}%` }}
    ></div>
  );
};

const DataUsageLine = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.dataUsageLine}>
      <div style={{ position: "relative" }}>
        {props.events &&
          props.events.map((event, i) => {
            return (
              <EventMark
                key={i}
                percent={event.level}
                type={event.type}
                text={event.text}
              />
            );
          })}
        <WindowMark percent={0} />
        <WindowMark percent={100} />
        <CurrentMark
          percent={props.currentLevel}
          currentLevelText={props.currentLevelText}
        />
      </div>
    </div>
  );
};

const DataUsageType = (props) => {
  const classes = useStyles();
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={classes.dataUsageLineTitle}>{props.title}</div>
      </div>
      <DataUsageLine
        events={props.events}
        currentLevel={(props.current / props.total) * 100}
        currentLevelText={`${props.current}`}
      ></DataUsageLine>
      <div className={classes.dataUsageLineHeaderContainer}>
        <div className={classes.currentUsageContainer}>
          <div className={classes.dataUsageHeaderNumber}>0</div>
          <div className={classes.dataUsageHeaderUnit}>GB</div>
        </div>

        <div className={classes.totalUsageContainer}>
          <div className={classes.dataUsageHeaderNumber}>{props.total}</div>
          <div className={classes.dataUsageHeaderUnit}>GB</div>
        </div>
      </div>
    </>
  );
};

const DataUsage = () => {
  const account = useAccountOrSelectAccount();
  if (account === undefined) {
    // If there is no selected account, then there is nothing to show.
    return <div />;
  }

  return (
    <ScreenSection title="Data Usage">
      {Object.keys(account.data_usage).length !== 0 ? (
        <>
          {account.data_usage["Fixed"] && (
            <DataUsageType
              title="Fixed Data"
              total={bytesToGigabytes(account.data_usage["Fixed"].sum_bytes)}
              current={bytesToGigabytes(
                account.data_usage["Fixed"].sum_bytes_accounted
              )}
              events={[50, 75, 100].map((i, _) => {
                return {
                  type: "alert",
                  level: i,
                  text: `${i}% (${
                    bytesToGigabytes(account.data_usage["Fixed"].sum_bytes) *
                    (i / 100)
                  }GB)`,
                };
              })}
            />
          )}
          {account.data_usage["Fixed Backup"] && (
            <DataUsageType
              title="Fixed Backup Data"
              total={bytesToGigabytes(
                account.data_usage["Fixed Backup"].sum_bytes
              )}
              current={bytesToGigabytes(
                account.data_usage["Fixed Backup"].sum_bytes_accounted
              )}
              events={[50, 75, 100].map((i, _) => {
                return {
                  type: "alert",
                  level: i,
                  text: `${i}% (${
                    bytesToGigabytes(
                      account.data_usage["Fixed Backup"].sum_bytes
                    ) *
                    (i / 100)
                  }GB)`,
                };
              })}
            />
          )}
          {account.data_usage["Roaming"] && (
            <DataUsageType
              title="Roaming Data"
              total={bytesToGigabytes(account.data_usage["Roaming"].sum_bytes)}
              current={bytesToGigabytes(
                account.data_usage["Roaming"].sum_bytes_accounted
              )}
              events={[50, 75, 100].map((i, _) => {
                return {
                  type: "alert",
                  level: i,
                  text: `${i}% (${
                    bytesToGigabytes(account.data_usage["Roaming"].sum_bytes) *
                    (i / 100)
                  }GB)`,
                };
              })}
            />
          )}
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
          }}
        >
          No data usages to report. <br />
          <br />
          New devices can sometimes take up to 24 hours to start reporting their
          usages.
        </div>
      )}
    </ScreenSection>
  );
};

const useStyles = makeStyles((theme) => ({
  dataUsageLineTitle: {
    fontSize: FONT_SIZES.xlarge,
  },
  eventMark: {
    position: "absolute",
    borderRadius: "5px",
    left: "-5px",
    border: "10px solid transparent",
    backgroundColor: COLORS.grey,
  },
  eventMarkTextContainer: {
    marginTop: "20px",
    textAlign: "center",
  },
  eventMarkTextType: {
    color: COLORS.darkBlue,
  },
  eventMarkText: {
    color: COLORS.grey,
  },
  currentMarkTextContainer: {
    marginTop: "-80px",
    marginLeft: "-100%",
    textAlign: "center",
  },
  currentMarkTextType: {
    color: COLORS.darkBlue,
  },
  currentMarkText: {
    color: COLORS.grey,
    fontSize: FONT_SIZES.large,
  },
  currentMarkTextNumber: {
    color: COLORS.darkBlue,
    fontSize: FONT_SIZES.xxlarge,
  },
  currentMarkTextUnit: {
    color: COLORS.grey,
    fontSize: FONT_SIZES.large,
  },
  windowMark: {
    position: "absolute",
    height: "20px",
    width: "20px",
    borderRadius: "10px",
    left: "-10px",
    marginTop: "-5px",
    backgroundColor: COLORS.darkBlue,
  },
  currentMarkLine: {
    position: "absolute",
    height: "20px",
    marginTop: "-5px",
    width: "4px",
    left: "-2px",
    backgroundColor: COLORS.grey,
  },
  currentMark: {
    position: "absolute",
    height: "10px",
    left: "0",
    backgroundColor: COLORS.darkBlue,
  },
  dataUsageLine: {
    marginTop: "100px",
    backgroundColor: COLORS.lightGrey,
    borderRadius: "5px",
    height: "10px",
    width: "100%",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  dataUsageLineHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "100px",
    marginTop: PADDING.small,
    // marginBottom: PADDING.medium,
  },
  currentUsageContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  totalUsageContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  dataUsageHeaderNumber: {
    fontSize: FONT_SIZES.xxxlarge,
  },
  dataUsageHeaderUnit: {
    fontSize: FONT_SIZES.large,
    color: COLORS.grey,
  },
}));

export default DataUsage;
