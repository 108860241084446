import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import "react-phone-input-2/lib/style.css";
import { COLORS, FONT_SIZES } from "../constants";
import ZetifiCard from "./ZetifiCard";

const LINE_HEIGHT = "40px";

const ZetifiAlertCard = (props) => {
  const classes = useStyles();
  const Icon = props.icon;

  return (
    <ZetifiCard onAccept={props.onAccept} acceptTitle={props.acceptTitle}>
      <div className={classes.container}>
        <div className={classes.columnOne}>
          <div
            className={classes.iconContainer}
            style={{ height: LINE_HEIGHT }}
          >
            <Icon></Icon>
          </div>
        </div>
        <div className={classes.columnTwo}>
          <div className={classes.title} style={{ lineHeight: LINE_HEIGHT }}>
            {props.title}
          </div>
          <div className={classes.subTitle}>{props.subTitle}</div>
        </div>
      </div>
    </ZetifiCard>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
    justifyContent: "left",
    textTransform: "none",
    textAlign: "left",
  },
  container: {
    display: "flex",
    flexDirection: "row",
  },
  columnOne: {
    display: "flex",
    marginLeft: "10px",
  },
  columnTwo: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "30px",
  },
  title: {
    fontSize: FONT_SIZES.large,
  },
  lineHeight: {
    lineHeight: "50px",
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
  },
  subTitle: {
    fontSize: FONT_SIZES.medium,
    color: COLORS.grey,
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

export default ZetifiAlertCard;
