import { API, Auth } from "aws-amplify";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "./Context";
import { Account, Me, Msg } from "./State";
let _cognitoUser: any = undefined;

export const getCognitoUserOnce = () => {
    let tmp = _cognitoUser;
    _cognitoUser = undefined;
    return tmp;
};

export const setCognitoUser = (cognitoUser: any) => {
    _cognitoUser = cognitoUser;
};

export async function tryGetJwtToken(): Promise<string | null> {
    try {
        const session = await Auth.currentSession();
        return await session.getIdToken().getJwtToken();
    } catch (e) {
        return null;
    }
}

// Fetch the latest version of me.
export async function fetchMe(history: any, dispatch: (msg: Msg) => void): Promise<Me | null> {
    try {
        const jwtToken = await tryGetJwtToken();

        if (jwtToken == null) {
            throw new Error("No session.");
        }

        let response = await API.get("api", `/me`, {
            headers: {
                Authorization: `${jwtToken}`,
            },
        });

        dispatch({ type: "SetMe", me: response });
        return response;
    } catch (e) {
        dispatch({ type: "Logout" });
        return null;
    }
}

// Go to the signin page if there is no valid session.
// Call this function before code that expects there to be a valid session.
export function useSessionOrSignin() {
    const history = useHistory();
    const [state, dispatch] = useContext(Context);

    // Only check if a session is valid once per page.
    useEffect(() => {
        (async () => {
            const jwtToken = await tryGetJwtToken();
            if (jwtToken == null) {
                dispatch({ type: "Logout" });
                history.push("/signin");
            }
        })();
    }, [history.location.key]);
}

// Get the current select account, if it isn't present go to the account select page.
// Call this funciton before code that expectes there to be a valid session and a selected account.
export function useAccountOrSelectAccount(): Account | undefined {
    const history = useHistory();
    const [state, dispatch] = useContext(Context);
    useSessionOrSignin();

    const account = state.me?.accounts.find((account) => account.id === state.current_account);

    // Only check if a session is valid once per page per account.
    useEffect(() => {
        if (account === undefined) {
            history.push("/selectAccount");
        }
    }, [history.location.key, state.current_account]);

    return account;
}
