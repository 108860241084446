// import { FONT_SIZES } from "../constants";
import Card from "@material-ui/core/Card";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import { Auth } from "aws-amplify";
import { Formik } from "formik";
import parsePhoneNumberFromString, {
  AsYouType,
  isValidPhoneNumber,
} from "libphonenumber-js";
import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { setCognitoUser } from "../../auth";
import ZetifiButton from "../../components/ZetifiButton";
import Button from "../../components/ZetifiButton";
import ZetifiCard from "../../components/ZetifiCard";
import TextField from "../../components/ZetifiTextField";
import Typography from "../../components/ZetifiTypography";
import BaseUnauthorized from "../BaseUnauthorized";

const getRandomString = (bytes) => {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues)
    .map((nr) => {
      return nr.toString(16).padStart(2, "0");
    })
    .join("");
};

const SignIn = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const validate = async (values) => {
    let { mobile } = values;

    mobile = parsePhoneNumberFromString(mobile, "AU")
      .formatInternational()
      .replace(/ /g, "");

    setLoading(true);

    try {
      try {
        await Auth.signUp({
          username: mobile,
          password: getRandomString(30),
        });
      } catch (err) {
        if (err.code !== "UsernameExistsException") throw err;
      }
    } catch (err) {
      toast.error("There was an issue signing in with that phone number.");
      setLoading(false);

      return false;
    }

    try {
      let cognitoUser = await Auth.signIn({
        username: mobile,
      });

      toast.success(
        "Success! Please enter the passcode we just sent to your mobile."
      );
      setLoading(false);
      setCognitoUser(cognitoUser);
      history.push("/verify");
    } catch (err) {
      toast.error("There was an issue signing in with that phone number.");
      setLoading(false);

      return false;
    }
  };

  return (
    <BaseUnauthorized
      title="Sign in"
      subTitle="Hi there, welcome to the Zetifi Portal."
    >
      <Card className={classes.formBox}>
        <Typography
          variant="h6"
          style={{ textAlign: "center", fontStyle: "italic" }}
          secondary
          paragraph
        >
          Please enter your mobile number.
        </Typography>

        <Formik
          initialValues={{
            mobile: "",
          }}
          onSubmit={(values) => validate(values)}
          validationSchema={Yup.object().shape({
            mobile: Yup.string("Enter your mobile number")
              .test(
                "valid-mobile-number",
                "Please enter a valid mobile number",
                (value) => {
                  if (value) {
                    return isValidPhoneNumber(value, "AU");
                  } else {
                    return false;
                  }
                }
              )
              .required("Mobile number is required"),
          })}
        >
          {({
            values,
            handleChange,
            errors,
            setFieldTouched,
            touched,
            isValid,
            handleSubmit,
            setFieldValue,
          }) => (
            <form>
              {/* {error ? <ErrorMessages errors={[error]} /> : null} */}
              <TextField
                className={classes.margin}
                type="text"
                onChange={(e) => {
                  setFieldValue(
                    "mobile",
                    new AsYouType("AU").input(e.target.value)
                  );
                  handleChange("mobile");
                }}
                onBlur={() => setFieldTouched("mobile")}
                value={values.mobile}
                label="Mobile Number"
                helperText="e.g. 0400 000 000"
                placeholder="0400 000 000"
                inputProps={{
                  style: { fontSize: "25px" },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIphoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <ZetifiButton
                type="submit"
                variant="contained"
                disabled={!isValid || loading}
                onClick={handleSubmit}
              >
                Submit
              </ZetifiButton>
            </form>
          )}
        </Formik>
      </Card>
    </BaseUnauthorized>
  );
};

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
    display: "flex",
  },
  formBox: {
    margin: "0 auto",
    maxWidth: "400px",
    padding: "25px",
  },
}));

export default SignIn;
