export interface Contact {
    first_name: string,
    last_name: string,
    email: string,
    mobile: string,
}

export interface Account {
    id: string,
    name: string,
    stripe: {
        sources_total_count: number,
        default_source: string,
        invoices: any[],
        subscriptions: any[],
    },
    data_usage: any,
}

export interface Me {
    contact: Contact,
    accounts: Account[]
}

export interface State {
    me: Me | null,
    // Id of the currently selected account.
    current_account: string | null
}

export type Msg =
    | { type: "SetMe"; me: Me }
    | { type: "Logout" }
    | { type: "SelectAccount"; current_account: string | null }

export const initialState: State = {
    me: null,
    current_account: null,
}

export function reducer(state: State, action: Msg): State {
    // Uncomment this line to log all global state changed.
    // console.log({ before: state, action: action });

    switch (action.type) {
        case "SetMe":
            let selected = state.current_account;

            // Ensure that current_account will still be valid.
            if (state.me !== null && action.me.accounts.every((account) => account.id !== selected)) {
                selected = null;
            }

            return {
                ...state,
                me: action.me,
                current_account: selected,
            };
        case "Logout":
            return {
                ...state,
                me: null,
                current_account: null,
            };
        case "SelectAccount":
            if (state.me !== null && state.me.accounts.some((account) => account.id === action.current_account)) {
                return {
                    ...state,
                    current_account: action.current_account,
                };
            } else {
                return {
                    ...state,
                    current_account: null,
                };
            }
        default:
            return state;
    }
};
