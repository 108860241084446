export const authConfig = {
  region: process.env.REACT_APP_REGION,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
};

export const apiConfig = {
  endpoints: [
    {
      name: "api",
      endpoint: process.env.REACT_APP_API_ENDPOINT,
    },
  ],
};
