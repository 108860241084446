import { toast } from "react-toastify";

class Alert {
  error(text) {
    toast.error(text);
  }

  success(text) {
    toast.success(text);
  }
}

export default new Alert();
