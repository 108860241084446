import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import "react-phone-input-2/lib/style.css";
import { COLORS } from "../constants";

const ZetifiCard = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.formBox}>
      {props.children}
      <div
        className={classes.actionButtons}
        style={{
          justifyContent:
            props.onCancel && props.onAccept ? "space-between" : "flex-end",
        }}
      >
        {props.onCancel ? (
          <Button
            onClick={props.onCancel}
            style={{ color: COLORS.grey }}
            disabled={props.cancelDisabled}
          >
            Cancel
          </Button>
        ) : null}
        {props.onAccept ? (
          <div className={classes.acceptButtonContainer}>
            <Button onClick={props.onAccept} disabled={props.acceptDisabled}>
              {props.acceptTitle || "Accept"}
            </Button>
            {props.acceptLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        ) : null}
      </div>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  acceptButtonContainer: {
    position: "relative",
  },
  actionButtons: {
    display: "flex",
  },
  formBox: {
    margin: "0 auto",
    padding: "25px",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default ZetifiCard;
