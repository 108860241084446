import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import moment from "moment";
import React from "react";
import ScreenOption from "../../../../components/ScreenOption";
import ScreenSection from "../../../../components/ScreenSection";

const CURRENCY_FORMATTER = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});

const BillRow = (props) => {
  return (
    <ScreenOption
      icon={ReceiptOutlinedIcon}
      title={props.title}
      titleRight={props.titleRight}
      subTitle={props.subTitle}
      onClick={props.onClick}
    ></ScreenOption>
  );
};

export default (props) => {
  return (
    <ScreenSection title="Bills">
      {props.invoices
        .filter((invoice) => {
          return invoice.status !== "draft";
        })
        .map((invoice, i) => (
          <BillRow
            key={i}
            title={moment(invoice.period_start * 1000).format("ll")}
            titleRight={`${CURRENCY_FORMATTER.format(invoice.total / 100)}`}
            subTitle={
              invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)
            }
            onClick={() =>
              window.open(invoice.hosted_invoice_url, "_blank").focus()
            }
          ></BillRow>
        ))}
    </ScreenSection>
  );
};
