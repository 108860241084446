import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import { Link } from "react-router-dom";
import Typography from "../components/ZetifiTypography";
import logo from "../images/logo.svg";
const DRAWER_WIDTH = 0;

function BaseUnauthorized(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const subTitles = Array.isArray(props.subTitle)
    ? props.subTitle
    : [props.subTitle];

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        {/* <div className={classes.toolbar} /> */}
        <div className={classes.logoContainer}>
          <Link to="/">
            <img src={logo} className={classes.logo} />
          </Link>
        </div>
        {props.title ? (
          <Typography
            variant="h4"
            style={{ textAlign: "center" }}
            paragraph={!props.subTitle}
          >
            {props.title}
          </Typography>
        ) : null}

        {subTitles && Array.isArray(subTitles)
          ? subTitles.map((subTitle, i) => {
              return (
                <Typography
                  variant="h6"
                  style={{ textAlign: "center" }}
                  secondary
                  paragraph
                  key={i}
                >
                  {subTitle}
                </Typography>
              );
            })
          : null}

        <div className={classes.childContent}>{props.children}</div>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
    borderRight: "none",
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
    boxShadow: "none",
    overflowX: "hidden",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH,
    },
  },
  menuButton: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: DRAWER_WIDTH,
    border: "none",
  },
  content: {
    margin: "0 auto",
    maxWidth: theme.breakpoints.values.sm,
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logo: {
    maxWidth: "270px",
    height: "auto",
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  listItemText: {
    color: theme.palette.text.secondary,
    fontWeight: "bold",
  },
  waves: {
    position: "relative",
    height: "auto",
    left: "-290px",
    width: "580px",
    [theme.breakpoints.up("lg")]: {
      left: "-340px",
      width: "680px",
    },
  },
  nestedListItem: {
    paddingLeft: theme.spacing(12),
  },
  childContent: {},
}));

export default BaseUnauthorized;
