import BaseAuthorized from "../../BaseAuthorized";
import Bills from "./components/Bills";
import PaymentSettings from "./components/PaymentSettings";
import { useAccountOrSelectAccount } from "../../../auth";

function Billing() {
  const account = useAccountOrSelectAccount();
  if (account === undefined) {
    // If there is no selected account, then there is nothing to show.
    return <div />
  }

  return (
    <BaseAuthorized title="Billing">
      {/* <ZetifiAlertCard
        icon={ErrorOutlineIcon}
        title="Overdue Bill"
        subTitle="Please pay as soon as you can."
        onAccept={() => {
          history.push("/billing");
        }}
        acceptTitle="Billing"
      ></ZetifiAlertCard> */}

      <PaymentSettings />
      <Bills invoices={account.stripe.invoices} />
    </BaseAuthorized>
  );
}

export default Billing;
