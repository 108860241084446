import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon, { ListItemIconProps } from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { boolean } from "yup/lib/locale";
import theme from "../theme"

const useStyles = makeStyles(() => ({
  listItemText: {
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    fontWeight: "bold",
    lineHeight: "1.43"
  },
}));

export const DrawerMenuIcon: React.FC<{
  text: string
  onClick?: () => void
  icon?: ListItemIconProps
  selected?: boolean,
  children?: JSX.Element
}> = ({ text, onClick, icon, selected, children }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  var expandChildren = null;
  var childrenContent = null;

  if (children != null) {
    if (open) {
      expandChildren = <ExpandLessIcon className={classes.listItemText} />;
    } else {
      expandChildren = <ExpandMoreIcon className={classes.listItemText} />;
    }

    childrenContent =
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div">{children}</List>
      </Collapse>;
  }

  return (
    <div>
      <ListItem
        button
        key={text}
        style={{
          borderTopRightRadius: "25px",
          borderBottomRightRadius: "25px",
        }}
        selected={selected}
        onClick={() => { setOpen(!open); if (onClick != null) { onClick() } }}
      >
        {icon != null && <ListItemIcon >{icon}</ListItemIcon>}
        <ListItemText primary={text} primaryTypographyProps={{ className: classes.listItemText }} />
        {expandChildren}
      </ListItem>
      {childrenContent}
    </div>
  );
}
