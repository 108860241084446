import CreditCardIcon from "@material-ui/icons/CreditCard";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { API } from "aws-amplify";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Alert from "../../../../Alert";
import ScreenOption from "../../../../components/ScreenOption";
import ScreenSection from "../../../../components/ScreenSection";
import ZetifiCard from "../../../../components/ZetifiCard";
import { Context } from "../../../../Context";
import { fetchMe, tryGetJwtToken, useAccountOrSelectAccount } from "../../../../auth";

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
    },
  },
};

const PaymentSettings = (props) => {
  const [editingPaymentSettings, setEditingPaymentSettings] = useState(false);
  const [acceptingPaymentSettings, setAcceptingPaymentSettings] =
    useState(false);
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const [cardEvent, setCardEvent] = useState({});

  const stripe = useStripe();
  const elements = useElements();

  const account = useAccountOrSelectAccount();
  if (account === undefined) {
    // If there is no selected account, then there is nothing to show.
    return <div />
  }

  const onAcceptPaymentSettings = async () => {
    setAcceptingPaymentSettings(true);

    const card = elements.getElement(CardElement);
    let result = await stripe.createToken(card);

    try {
      const jwtToken = await tryGetJwtToken();

      if (jwtToken == null) {
        throw new Error("No session.");
      }

      await API.post("api", `/billing/updateCard`, {
        headers: {
          Authorization: `${jwtToken}`,
        },
        body: {
          accountId: state.current_account,
          cardId: result.token.id,
        },
      });

      // Fetch the latest state.
      await fetchMe(history, dispatch);

      Alert.success("Credit card successfully captured.");
    } catch (e) {
      Alert.error("Error saving card.");
    } finally {
      setAcceptingPaymentSettings(false);
      setEditingPaymentSettings(false);
    }
  };

  const handleCreditCardChange = (event) => {
    setCardEvent(event);
    if (event.error) {
      Alert.error(event.error.message);
    }
  };

  return (
    <ScreenSection title="Payment Settings">
      <>
        {editingPaymentSettings ? (
          <ZetifiCard
            onCancel={() => {
              fetchMe(history, dispatch);
              setEditingPaymentSettings(false);
            }}
            onAccept={onAcceptPaymentSettings}
            acceptLoading={acceptingPaymentSettings}
            acceptDisabled={acceptingPaymentSettings || !cardEvent.complete}
            cancelDisabled={acceptingPaymentSettings}
          >
            <CardElement
              options={CARD_ELEMENT_OPTIONS}
              onChange={handleCreditCardChange}
            />
          </ZetifiCard>
        ) : (
          <ScreenOption
            icon={CreditCardIcon}
            onClick={() => {
              setEditingPaymentSettings(true);
            }}
            title="Payment Method"
            subTitle={
              account.stripe.default_source
                ? `${account.stripe.default_source.brand} •••• ${account.stripe.default_source.last4}`
                : "No Payment Method"
            }
          ></ScreenOption>
        )}
      </>
    </ScreenSection>
  );
};

export default () => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_ZETIFI_STRIPE_PUBLISHABLE_KEY
  );

  return (
    <Elements stripe={stripePromise}>
      <PaymentSettings></PaymentSettings>
    </Elements>
  );
};
