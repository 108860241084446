import { createContext } from "react";
import { Msg, initialState, State } from "./State";

export const Context = createContext<[
    State,
    (msg: Msg) => void,
]>([
    initialState,
    (msg: Msg) => { }
]);
