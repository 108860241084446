import TextField from "@material-ui/core/TextField";
import React from "react";

export default (props) => {
  //   return props.secondary ? (
  //     <Typography {...props} classes={{ root: classes.secondary }}>
  //       {props.children}
  //     </Typography>
  //   ) : (
  //     <Typography {...props}>{props.children}</Typography>
  //   );

  return <TextField type="text" {...props} />;
};
