import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useStyles = makeStyles((theme) => ({
  secondary: {
    ...theme.typography.secondary,
  },
}));

export default (props) => {
  const classes = useStyles();

  const childProps = { ...props };
  delete childProps.secondary;

  return props.secondary ? (
    <Typography {...childProps} classes={{ root: classes.secondary }}>
      {props.children}
    </Typography>
  ) : (
    <Typography {...childProps}>{props.children}</Typography>
  );
};
