// import { FONT_SIZES } from "../constants";
import Card from "@material-ui/core/Card";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import { Auth } from "aws-amplify";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getCognitoUserOnce } from "../../auth";
import ZetifiButton from "../../components/ZetifiButton";
import ZetifiCard from "../../components/ZetifiCard";
import SecondaryButton from "../../components/ZetifiSecondaryButton";
import TextField from "../../components/ZetifiTextField";
import Typography from "../../components/ZetifiTypography";
import BaseUnauthorized from "../BaseUnauthorized";

const Home = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const cognitoUser = useRef(undefined);
  const classes = useStyles();

  useEffect(() => {
    let maybeCognitoUser = getCognitoUserOnce();
    if (maybeCognitoUser) {
      cognitoUser.current = maybeCognitoUser;
    } else {
      history.push("/");
    }
  }, []);

  const answerChallenge = async (values) => {
    const { oneTimePassword } = values;
    setLoading(true);

    Auth.sendCustomChallengeAnswer(cognitoUser.current, oneTimePassword)
      .then((cognitoUser) => {
        if (cognitoUser.signInUserSession === null) {
          toast.error("Passcode incorrect.");
          setLoading(false);
        } else {
          toast.success("Login successful.");
          history.push("/selectAccount");
        }
      })
      .catch((res) => {
        toast.error("Passcode incorrect.");
        setLoading(false);
      });
  };

  return (
    <BaseUnauthorized title="Verify" subTitle="Almost done!">
      <Card className={classes.formBox}>
        <Typography
          variant="h6"
          style={{ textAlign: "center", fontStyle: "italic" }}
          secondary
          paragraph
        >
          Please enter the code we just sent to your mobile.
        </Typography>

        <Formik
          initialValues={{
            oneTimePassword: "",
          }}
          onSubmit={(values) => answerChallenge(values)}
          validationSchema={Yup.object().shape({
            oneTimePassword: Yup.string("One Time Passcode")
              .length(6)
              .required("One Time Passcode is required"),
          })}
        >
          {({
            values,
            handleChange,
            errors,
            setFieldTouched,
            touched,
            isValid,
            handleSubmit,
            setFieldValue,
          }) => (
            <form>
              {/* {error ? <ErrorMessages errors={[error]} /> : null} */}
              <TextField
                className={classes.margin}
                type="text"
                onChange={handleChange("oneTimePassword")}
                value={values.oneTimePassword}
                touched={touched.oneTimePassword}
                error={errors.oneTimePassword}
                placeholder="000000"
                inputProps={{
                  style: { fontSize: "25px" },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <ZetifiButton
                type="submit"
                variant="contained"
                disabled={!isValid || loading}
                onClick={handleSubmit}
              >
                Submit
              </ZetifiButton>
              <SecondaryButton
                variant="contained"
                onClick={() => {
                  history.push("/signin");
                }}
                style={{ marginTop: "10px" }}
              >
                Cancel
              </SecondaryButton>
            </form>
          )}
        </Formik>
      </Card>
    </BaseUnauthorized>
  );
};

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
    display: "flex",
  },
  formBox: {
    margin: "0 auto",
    maxWidth: "400px",
    padding: "25px",
  },
}));

export default Home;
