import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import ZetifiAlertCard from "../../../components/ZetifiAlertCard";
import { Context } from "../../../Context";
import BaseAuthorized from "../../BaseAuthorized";
import DataUsage from "./components/DataUsage";
import { useAccountOrSelectAccount } from "../../../auth"

function Home(props) {
  const history = useHistory();
  const [state, dispatch] = useContext(Context);

  const account = useAccountOrSelectAccount();
  if (account === undefined) {
    // If there is no selected account, then there is nothing to show.
    return <div />
  }

  return (
    <BaseAuthorized
      title={`Hi, ${state.me.contact.first_name}`}
      subTitle="Welcome to Zetifi. We're glad you're aboard!"
    >
      <>
        {!account.stripe.default_source && (
          <ZetifiAlertCard
            icon={ErrorOutlineIcon}
            title="No Payment Method Setup"
            subTitle="Please go to billing and enter payment details."
            onAccept={() => {
              history.push("/billing");
            }}
            acceptTitle="Billing"
          ></ZetifiAlertCard>
        )}
        <DataUsage></DataUsage>
      </>
    </BaseAuthorized>
  );
}

export default Home;
