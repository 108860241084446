import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../Context";
import { Card, CircularProgress, FormControlLabel, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import { fetchMe } from "../../auth";
import BaseUnauthorized from "../BaseUnauthorized";
import ZetifiButton from "../../components/ZetifiButton";

export const SelectAccount: React.FC = () => {
    const [state, dispatch] = useContext(Context);
    const [selected, setSelected] = useState<string | null>(null);
    const history = useHistory();

    const classes = useStyles();

    useEffect(() => {
        (async () => {
            // Send an asynchronous request to server to fetch details about authenticated user.
            if (state.me == null) {
                const new_me = await fetchMe(history, dispatch);

                if (new_me != null && new_me.accounts.length == 1) {
                    dispatch({ type: "SelectAccount", current_account: new_me.accounts[0].id })
                    history.push("/home");
                }
            }
        })();
    }, []);

    if (state.me == null) {
        return <BaseUnauthorized
            title="Loading..."
            subTitle="Hold on while we fetch your information."
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyItems: "center",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                }}
            >
                <div style={{ flex: 1 }}>
                    <CircularProgress />
                </div>
            </div>
        </BaseUnauthorized>;
    } else {

        const accounts = state.me.accounts.map((account) => {
            return <FormControlLabel key={account.id} value={account.id} label={account.name} control={<Radio />} />;
        });

        const handleRadioChange = (_event: ChangeEvent<HTMLInputElement>, value: string) => {
            setSelected(value);
        }

        const handleSubmit = () => {
            if (selected != null) {
                dispatch({ type: "SelectAccount", current_account: selected })
                history.push("/home");
            }
        }

        return <BaseUnauthorized
            title="Select Account"
            subTitle="Select which account you would like to use."
        >
            <Card className={classes.formBox}>
                <RadioGroup className={classes.margin} value={selected} onChange={handleRadioChange}>
                    {accounts}
                </RadioGroup>
                <ZetifiButton variant="contained" onClick={handleSubmit}>
                    Sign In
                </ZetifiButton>
            </Card>
        </BaseUnauthorized >;
    }
};

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
        display: "flex",
    },
    formBox: {
        margin: "0 auto",
        maxWidth: "400px",
        padding: "25px",
    },
}));

export default SelectAccount;