export const COLORS = {
  darkBlue: "#01053e",
  green: "#99a3ae",
  blue: "#04bde6",
  successGreen: "#28a745",
  errorRed: "#bb0000",
  grey: "grey",
  backgroundColor: "white",
  amber: "rgb(246, 156, 8)",
  linkColor: "blue",
  lightGrey: "#EEF0F1",
};

export const PADDING = {
  small: 10,
  medium: 15,
  large: 20,
};

export const FONT_SIZES = {
  small: 14,
  medium: 16,
  large: 18,
  xlarge: 24,
  xxlarge: 30,
  xxxlarge: 36,
};
