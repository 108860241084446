import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "react-phone-input-2/lib/style.css";

const ZetifiButton = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontSize: "20px",
    width: "100%",
    fontWeight: "bold",
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(Button);

export default ZetifiButton;
