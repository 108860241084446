import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { COLORS, FONT_SIZES } from "../constants";

const LINE_HEIGHT = "40px";

export default (props) => {
  const classes = useStyles();
  const Icon = props.icon;

  const content = (
    <div className={classes.container}>
      <div className={classes.columnOne}>
        <div className={classes.iconContainer} style={{ height: LINE_HEIGHT }}>
          <Icon></Icon>
        </div>
      </div>
      <div className={classes.columnTwo}>
        <div className={classes.title} style={{ lineHeight: LINE_HEIGHT }}>
          {props.title}
        </div>
        <div className={classes.subTitle}>{props.subTitle}</div>
      </div>
      <div className={classes.columnThree}>
        <div className={classes.title} style={{ lineHeight: LINE_HEIGHT }}>
          {props.titleRight}
        </div>
      </div>
    </div>
  );

  return (
    <Button className={classes.button} onClick={props.onClick}>
      {content}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    width: "100%",
    justifyContent: "left",
    textTransform: "none",
    textAlign: "left",
  },
  container: {
    display: "flex",
    flexDirection: "row",
  },
  columnOne: {
    display: "flex",
    marginLeft: "10px",
  },
  columnTwo: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "50px",
    flexGrow: 1,
  },
  columnThree: {
    marginRight: "25px",
  },
  title: {
    fontSize: FONT_SIZES.large,
  },
  lineHeight: {
    lineHeight: "50px",
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
  },
  subTitle: {
    fontSize: FONT_SIZES.medium,
    color: COLORS.grey,
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
}));
