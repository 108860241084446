import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "react-phone-input-2/lib/style.css";

const ZetifiSecondaryButton = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontSize: "20px",
    width: "100%",
    fontWeight: "bold",
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: "rgba(0, 0, 0, 0.26)",
  },
}))(Button);

export default ZetifiSecondaryButton;
