import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import MeetingRoomOutlinedIcon from "@material-ui/icons/MeetingRoomOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import { Auth } from "aws-amplify";
import React from "react";
import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Typography from "../components/ZetifiTypography";
import logoWaves from "../images/logo-waves.svg";
import logo from "../images/logo.svg";
import { Context } from "../Context";
import { DrawerMenuIcon } from "../components/DrawerMenuIcon";
import { AccountBox } from "@material-ui/icons";
import { useAccountOrSelectAccount } from "../auth";

const DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
    borderRight: "none",
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
    boxShadow: "none",
    overflowX: "hidden",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH,
    },
  },
  menuButton: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: DRAWER_WIDTH,
    border: "none",
  },
  content: {
    margin: "0 auto",
    maxWidth: theme.breakpoints.values.sm,
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logo: {
    width: `calc(100% - ${theme.spacing(12)}px)`,
    height: "auto",
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  listItemText: {
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    fontWeight: "bold",
    lineHeight: "1.44"
  },
  waves: {
    position: "relative",
    height: "auto",
    left: "-290px",
    width: "580px",
    [theme.breakpoints.up("lg")]: {
      left: "-340px",
      width: "680px",
    },
  },
  nestedListItem: {
    paddingLeft: theme.spacing(12),
  },
  childContent: {},
}));

function BaseAuthorized(props) {
  const { window } = props;
  const [state, dispatch] = useContext(Context);
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const account = useAccountOrSelectAccount();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Link
        to="/home"
        className={`${classes.toolbar} ${classes.logoContainer}`}
      >
        <img className={classes.logo} src={logo} />
      </Link>
      {state.me.accounts.length > 1 && <div style={{ textAlign: "center", paddingLeft: 25, paddingRight: 25 }}>{account.name}</div>}
      <List>
        <DrawerMenuIcon
          text="Home"
          selected={history.location.pathname === "/home"}
          icon={<HomeOutlinedIcon />}
          onClick={() => {
            history.push("/home");
          }}
        ></DrawerMenuIcon>
        {/* <DrawerMenuIcon text="Account" icon={<SupervisorAccountOutlinedIcon />}>
          <DrawerMenuIcon text="My Plans"></DrawerMenuIcon>
          <DrawerMenuIcon text="My Devices"></DrawerMenuIcon>
        </DrawerMenuIcon> */}
        <DrawerMenuIcon
          text="Billing"
          selected={history.location.pathname === "/billing"}
          icon={<ReceiptOutlinedIcon />}
          onClick={() => {
            history.push("/billing");
          }}
        ></DrawerMenuIcon>
      </List>
      <Divider />
      <List>
        {/* <DrawerMenuIcon text="Settings" icon={<SettingsOutlinedIcon />}>
          <DrawerMenuIcon text="Service address"></DrawerMenuIcon>
          <DrawerMenuIcon text="History"></DrawerMenuIcon>
          <DrawerMenuIcon text="Notifications"></DrawerMenuIcon>
          <DrawerMenuIcon text="Privacy"></DrawerMenuIcon>
        </DrawerMenuIcon> */}

        {state.me.accounts.length > 1 && <DrawerMenuIcon text="Change Account" icon={<AccountBox />} onClick={() => {
          dispatch({ type: "SelectAccount", current_accoun: null });
          history.push("/selectAccount");
        }} />}

        <DrawerMenuIcon
          text="Logout"
          icon={<MeetingRoomOutlinedIcon />}
          onClick={async () => {
            await Auth.signOut();
            toast.success("Successfully logged out.");
            dispatch({ type: "Logout" });
            history.push("/signin");
          }}
        />
      </List>
    </div >
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const subTitles = Array.isArray(props.subTitle)
    ? props.subTitle
    : [props.subTitle];

  return (
    <div className={classes.root}>
      <AppBar position="absolute" className={classes.appBar}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            overflowX: "hidden",
            overflowY: "hidden",
          }}
        >
          <div style={{ width: "1px", margin: "0 auto" }}>
            <img src={logoWaves} className={classes.waves} />
          </div>
        </div>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.title ? (
          <Typography
            variant="h4"
            style={{ textAlign: "center" }}
            paragraph={!subTitles}
          >
            {props.title}
          </Typography>
        ) : null}

        {subTitles && Array.isArray(subTitles)
          ? subTitles.map((subTitle, i) => {
            return (
              <Typography
                variant="h6"
                style={{ textAlign: "center" }}
                secondary
                paragraph
                key={i}
              >
                {subTitle}
              </Typography>
            );
          })
          : null}

        <div className={classes.childContent}>{props.children}</div>
      </main>
    </div>
  );
}

export default BaseAuthorized;
