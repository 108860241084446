import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  typography: {
    fontFamily: ["Fieldwork", "arial"].join(","),
    secondary: {
      fontFamily: ["Proxima Nova", "arial"].join(","),
    },
  },
  palette: {
    primary: {
      main: "#01053e",
    },
    secondary: {
      main: "#04bde6",
    },
    background: {
      default: "white",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 740, // Was originally 600
      md: 840, // Was originally 960
      lg: 1280,
      xl: 1920,
    },
  },
});

export default theme;
