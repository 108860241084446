import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
    fontWeight: 300,
  },
}));

export default (props) => {
  const classes = useStyles();

  return (
    <div>
      <h4 className={classes.text}>{props.title}</h4>
      {props.children}
    </div>
  );
};
